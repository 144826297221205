import Glide from "@glidejs/glide";

class ShopsTabs {
    constructor(selector) {
        this.selector = selector;
        this.tabs = this.selector.querySelector('[data-element="tabs"]');
        this.container = this.selector.querySelector('[data-element="container"]');

        this.tabs.querySelectorAll('a').forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                let target = e.target.getAttribute('data-tab');
                this.tabs.querySelectorAll('a').forEach(t => {
                    t.parentNode.classList.remove('active');
                });
                e.target.parentNode.classList.add('active');

                if (target !== null) {
                    this.container.querySelectorAll('[data-target]').forEach(el => {
                        if (el.classList.contains('shops-carousel-item--active')){
                            el.classList.remove('shops-carousel-item--active');
                        }
                    });
                    let element = this.container.querySelector('[data-target="' + target + '"]')
                    element.classList.add('shops-carousel-item--active');
                    let glide = new Glide(element.querySelector('.js-our-shops-carousel'), {
                        perView: 6,
                        gap: '25',
                        bound: true,
                        breakpoints: {
                            1300: {
                                perView: 5,
                            },
                            1000: {
                                perView: 4,
                            },
                            991: {
                                perView: 3,
                            },
                            767: {
                                perView: 2,
                                autoplay: 3000,
                            },
                        }
                    });
                    glide.mount();
                }
            });
        });
    }
}
export default ShopsTabs;