import * as basicLightbox from "basiclightbox";

class ShopsList {
    constructor(selector) {
        this.selector = selector;

        this.loadMore = this.selector.querySelector('[data-element="load-more"]');
        this.filterForm = this.selector.querySelector('[data-element="form"]');
        this.postContainer = this.selector.querySelector('[data-element="container"]');
        this.categorySelect = this.selector.querySelector('[data-element="categories"]');
        this.searchInput = this.selector.querySelector('[data-element="search"]');
        this.postType = this.selector.getAttribute('data-post-type');
        this.loadMorePoint = this.selector.querySelector('[data-element="load-more-point"]');
        this.loader = this.selector.querySelector('[data-element="loader"]');

        this.lastPage = false;
        this.isLoading = false;

        this._submitForm = this._submitForm.bind(this);
        this._scrollEvent = this._scrollEvent.bind(this);
        this._nextPageEvent = this._nextPageEvent.bind(this);

        this._loadAttributes();

        this._initActions();

        if(this.loadOnInit){
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }
        let cards_count = this.postContainer.querySelectorAll('.card');
        if (cards_count.length < this.perPage) {
            this.lastPage = true;
        }
    }

    _loadAttributes(){

        let dataAllId = this.selector.getAttribute('data-all-id');
        if (dataAllId !== null && dataAllId !== '') {
            this.dataAllId = dataAllId;
        } else this.dataAllId = 0;

        let categories = this.selector.getAttribute('data-category');
        if(categories !== null && categories !== ''){
            this.categories = categories.split(',');
        } else {
            if (this.dataAllId !== undefined && this.dataAllId !== null && this.dataAllId !== 0) {
                this.categories = [this.dataAllId];
            } else this.categories = [];
        }

        let search = this.selector.getAttribute('data-search');
        if(search !== null && search !== ''){
            this.search = search;
        } else this.search = '';

        let post_type = this.selector.getAttribute('data-post-type');
        if (post_type  !== null && post_type !== '') {
            this.postType = post_type;
        } else this.postType = '';

        let page = this.selector.getAttribute('data-page');
        if(page !== null && page !== ''){
            this.page = parseInt(page);
        } else this.page = 1;

        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;

    }

    _initActions(){
        if (this.filterForm !== null) {
            this.filterForm.addEventListener('submit', this._submitForm);
        }
        if (this.postType == 'shop') {
            if (this.categorySelect !== null) {
                this.categorySelect.addEventListener('change', this._submitForm);
            }
        }
        if (this.searchInput !== null) {
            this.searchInput.addEventListener('change', this._submitForm);
        }
        if (this.loadMore !== null) {
            this.loadMore.addEventListener('click', this._nextPageEvent);
        }
        if(this.loadMorePoint !== null) {
            window.addEventListener('scroll', this._scrollEvent);
        }
    }

    _submitForm(event) {
        event.preventDefault();
        if (!this.isLoading) {
            this.isLoading = true;

            this.page = 1;
            this._getFormData();
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }
    }

    _nextPageEvent(event){
        event.preventDefault();
        if(!this.lastPage) {
            this._nextPage();
        }
    }

    _scrollEvent(event){
        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 200;
        let window_bottom = (window_top + window_height);

        let element_top = document.body.scrollTop + this.loadMorePoint.getBoundingClientRect().top;

        if ((element_top >= window_top) && element_top <= window_bottom && !this.isLoading && !this.lastPage) {
            this._nextPage();
        }
    }

    _nextPage(){
        this.page++;
        this._loadPosts();
    }

    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/' + this.postType + '?'+queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _buildQuery(){
        // console.log(this.categories, this.search);

        let query = [
            'per_page=' + this.perPage,
            'page=' + this.page,
            'order=asc',
            'orderby=title'
        ];

        if(this.categories.length > 0){
            query.push('shop_categories[terms]=' + this.categories.join(','));
            query.push('shop_categories[include_children]=1');
        }

        // if(this.types.length > 0){
        //     query.push('nt_type=' + this.types.join(','));
        // }

        if(this.search !== ''){
            query.push('search=' + this.search);
        }
        query.push('_embed');
        query.push('')

        return query.join('&');
    }

    _loadPosts(){
        this._showLoaderAnimation();
        this.isLoading = true;
        this._getFromAPI().then(response => {
            if(response.length > 0){
                this._generatePosts(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }

    _generatePosts(posts){
        posts.forEach(post => {
            let postElement = this._generatePost(post);
            this.postContainer.append(postElement);
        });
        this._hideLoaderAnimation();
        if(posts.length < this.perPage){
            this.lastPage = true;
            this._hideLoadMoreButton();
        }
        this.isLoading = false;
    }

    _generatePost(post){
        console.log(post);

        let card = document.createElement('div');
        card.classList.add('card');

        if (this.postType == 'shop') {
            card.classList.add('card--gastronomy');
        } else if (this.postType == 'promotion') {
            card.classList.add('card--promotion');
        } else if (this.postType == 'posts') {
            card.classList.add('card--recent-event');
            card.classList.add('card--event-on-list');
            card.classList.add('card--highlighted');
        }


        let image = document.createElement('div');
        image.classList.add('card__image');

        let img = document.createElement('img');
        img.setAttribute('alt', post.title.rendered);

        // console.log(post._embedded["wp:featuredmedia"][0].source_url);

        let source = '';
        if (post._embedded["wp:featuredmedia"] !== undefined) {
            if (post._embedded["wp:featuredmedia"][0].source_url !== undefined) {
                source = post._embedded["wp:featuredmedia"][0].source_url;
            }
        } else {
            if (post.nt_shop_logo_placeholder !== null) {
                source = post.nt_shop_logo_placeholder;
            }
        }
        let file_url = source.slice(0, source.lastIndexOf('/'));
        let src = '';
        if (this.postType == 'shop') {
            if (post._embedded["wp:featuredmedia"] !== undefined) {
                if (post._embedded["wp:featuredmedia"][0].media_details !== undefined) {
                    if (post._embedded["wp:featuredmedia"][0].media_details !== undefined && post._embedded["wp:featuredmedia"][0].media_details.sizes["logo-carousel-thumbnail"] !== undefined) {
                        src = file_url + '/' + post._embedded["wp:featuredmedia"][0].media_details.sizes["logo-carousel-thumbnail"].file;
                    } else {
                        src = source;
                    }
                } else src = source;
            } else src = source;
        } else if (this.postType == 'promotion') {
            if (post.nt_promotion_related_shop_logo !== undefined) {
                src = post.nt_promotion_related_shop_logo;
            }
        }

        img.setAttribute('src', src);

        let category = null;
        if (this.postType == 'shop') {

            if (post.nt_category_link[0].link !== null) {
                category = document.createElement('a');
                category.setAttribute('href', post.nt_category_link[0].link);
            } else {
                category = document.createElement('div');
            }
            category.classList.add('card__category');
            category.innerText = post.nt_categories[0].name;
        } else if (this.postType == 'promotion') {
            category = document.createElement('div');
            category.classList.add('card__category');
            category.innerText = post.nt_categories;
        }

        let title = document.createElement('h3');
        title.classList.add('card__title');
        title.classList.add('title');
        if (this.postType == 'promotion' || this.postType == 'posts') {
            title.classList.add('title--size-24');
        }

        let title_link = document.createElement('a');
        title_link.setAttribute('href', post.link);
        title_link.innerText = post.title.rendered;

        let excerpt_cnt = document.createElement('div');
        excerpt_cnt.classList.add('card__exception');
        excerpt_cnt.innerHTML = post.nt_excerpt;

        let data = document.createElement('div');
        data.classList.add('card__data');

        let button = document.createElement('a');
        button.classList.add('button');
        button.classList.add('button--inverted');
        button.classList.add('button--arrow');
        button.classList.add('button--arrow-orange');
        button.classList.add('button--arrow-orange-right');
        button.setAttribute('href', post.link);
        button.innerText = 'Więcej';

        let card_container = document.createElement('div');
        card_container.classList.add('card__container');

        if (this.postType == 'posts') {
            if (post.nt_event_thumbnail_list !== undefined) {
                image.style.cssText = "background-image: url('" + post.nt_event_thumbnail_list + "');";
            }
            let card_date = document.createElement('div');
            card_date.classList.add('card__date');
            let start_date = new Date(post.date);
            let date_day = start_date.getDay();
            let date_month = start_date.getMonth();
            if (date_day !== '' && date_month !== '') {
                card_date.innerText = ((date_day < 10) ? '0' + date_day : date_day) + '.' + ((date_month < 10) ? '0' + date_month : date_month);
                image.append(card_date);
            }
        } else {
            image.append(img);
        }

        card.append(image);

        if (this.postType == 'shop' || this.postType == 'promotion') {
            card.append(category);
        }

        if (this.postType == 'posts') {
            title.append(title_link);
            card_container.append(title);
            card_container.append(excerpt_cnt);
            data.append(button);
            card_container.append(data);
            card.append(card_container);
        } else {
            title.append(title_link);
            card.append(title);
            card.append(excerpt_cnt);
            data.append(button);

            if (this.postType == 'shop') {
                let plan_level = document.createElement('span');
                plan_level.classList.add('card__plan-level');
                plan_level.innerText = post.meta._nt_plan_level_name;
                data.append(plan_level);
            }

            if (this.postType == 'promotion') {
                let discount = document.createElement('div');
                discount.classList.add('card__discount');
                discount.innerHTML = '-' + post.meta._nt_discount;
                data.append(discount);
            }
            card.append(data);
        }

        return card;
    }

    _noResults(){
        if(this.page === 1){
            let element = document.createElement('div');
            element.classList.add('title');
            element.classList.add('title--size-24');
            element.innerText = 'Brak wpisów spełniających kryteria';
            this.postContainer.appendChild(element);
            this._hideLoaderAnimation();
        } else {
            this.lastPage = true;
            this._hideLoadMoreButton();
        }
    }

    _handleError(error){
        console.log(error);
        if (error.message === 'rest_post_invalid_page_number') {
            this.lastPage = true;
            this._hideLoadMoreButton();
        } else {
            const div = document.createElement('div');
            const modal = document.createElement('div');
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal', 'modal--load-posts-error');
            modal.setAttribute('data-element', 'modal');
            modal.innerText = 'Wystąpił błąd podczas ładowania publikacji, odśwież stronę i spróbuj ponownie.';

            modal.appendChild(close);
            div.appendChild(modal);

            let instance = basicLightbox.create(div);
            instance.show();

            close.addEventListener('click', (e) => {
                instance.close();
                instance.innerHTML = '';
            });
        }
        this._hideLoaderAnimation();
        this.isLoading = false;
    }

    _showLoaderAnimation() {
        this.loader.classList.add('cards-list-loader--show');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('cards-list-loader--show');
    }
    _hideLoadMoreButton() {
        this.loadMore.style.display = 'none';
    }
    _getFormData() {
        this.categories = [];

        if (this.postType == 'shop') {
            if (this.categorySelect.value !== '' && this.categorySelect.value !== null) {
                this.categories = [this.categorySelect.value];
            } else {
                if (this.dataAllId !== undefined && this.dataAllId !== null && this.dataAllId !== 0) {
                    this.categories = [this.dataAllId];
                } else this.categories = [];
            }
        }

        // console.log(this.categories, this.dataAllId);

        let search = this.filterForm.querySelector('[name="s"]');
        if (search !== null) {
            this.search = search.value;
        }
    }
}
export default ShopsList;