import Glide from '@glidejs/glide';
// import Glider from 'glider-js/glider';
import Choices from "choices.js";
import ShopsList from "./shopsList";
import Conveniences from "./conveniences";
import { initMap, Form } from '@netivo/base-scripts';
import ShopsTabs from "./shopsTabs";
import CalculateRects from "./calculateRect";
import GalleryPlan from "./galleryPlan";

function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

window.initMap = initMap;

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

let lang_menu_btn = document.querySelector('.lang-menu-button');
let lang_menu =  document.querySelector('.lang-menu');
if(lang_menu_btn !== null && lang_menu !== null){
    lang_menu_btn.addEventListener('click', function (e){
        lang_menu.classList.toggle('lang-menu--shown');
    });
    document.addEventListener('click', function(e){
        if(e.target !== lang_menu_btn && !lang_menu_btn.contains(e.target)) {
            lang_menu.classList.remove('lang-menu--shown');
        }
    })
}

let header = document.querySelector('.js-header');
let sticky = header.offsetTop;

window.onscroll = function(){stickyStart()};

function stickyStart() {
    if(window.pageYOffset > sticky){
        header.classList.add('header--scrolled');
    } else {
        header.classList.remove('header--scrolled');
    }
}

window.addEventListener('DOMContentLoaded', () => {

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        new Form(contact_form, {
            action: '/wp-json/rondo/v1/contact_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_last_name: '',
                        email: '',
                        message: ''
                    };

                    let form_first_last_name = el.querySelector('[name="first_last_name"]'),
                        form_email = el.querySelector('[name="addres_email"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_first_last_name !== null) data.first_last_name = form_first_last_name.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_message !== null) data.message = form_message.value;

                    resolve( data );
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    let form_bcg = document.querySelector('.form__background');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    form_bcg.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="first_last_name"]').value = '';
                        el.querySelector('[name="addres_email"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                    }, 500);

                    let close = message.querySelector('[data-element="close-button"]');
                    let close_top = message.querySelector('[data-element="close-button-top"]');
                    if (close !== null && close_top !== null) {
                        close.addEventListener('click', (e) => {
                            e.preventDefault();
                            message.classList.remove('shown');
                            form_bcg.classList.remove('shown');
                            setTimeout( () => {
                                status.classList.remove('shown');
                            }, 500);
                        });
                        close_top.addEventListener('click', (e) => {
                            e.preventDefault();
                            message.classList.remove('shown');
                            form_bcg.classList.remove('shown');
                            setTimeout( () => {
                                status.classList.remove('shown');
                            }, 500);
                        });
                    }
                }
            },
            error: (el, response) => {
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');
                let form_bcg = document.querySelector('.form__background');
                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');
                form_bcg.classList.add('shown');
                setTimeout( () => {
                    message.classList.add('shown');
                }, 500);

                let close = message.querySelector('[data-element="close-button"]');
                let close_top = message.querySelector('[data-element="close-button-top"]');
                if (close !== null && close_top !== null) {
                    close.addEventListener('click', (e) => {
                        e.preventDefault();

                        message.classList.remove('shown');
                        form_bcg.classList.remove('shown');
                        setTimeout( () => {
                            status.classList.remove('shown');
                        }, 500);
                    });
                    close_top.addEventListener('click', (e) => {
                        e.preventDefault();

                        message.classList.remove('shown');
                        form_bcg.classList.remove('shown');
                        setTimeout( () => {
                            status.classList.remove('shown');
                        }, 500);
                    });
                }
            }
        });
    }


    let os_crls = null;
    let our_shops_carousel = document.querySelector('.js-our-shops-carousel');
    if (our_shops_carousel !== null) {
        os_crls = new Glide(our_shops_carousel, {
            perView: 6,
            gap: '25',
            bound: true,
            breakpoints: {
                1300: {
                    perView: 5,
                },
                1000: {
                    perView: 4,
                },
                991: {
                    perView: 3,
                },
                767: {
                    perView: 2,
                    autoplay: 3000,
                },
            }
        }).mount()
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let e_crls = null;
    let events_carousel = document.querySelector('.js-events-carousel');
    if (events_carousel !== null) {
        let prev_button = events_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = events_carousel.parentNode.querySelector('[data-element="button-next"]');

        e_crls = new Glide(events_carousel, {
            perView: 1,
            gap: '25',
            bound: true,
            breakpoints: {
                991: {
                    perView: 1,
                },
                600: {
                    perView: 1,
                    autoplay: 3000
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('>');
        });
    }

    let carousel_elements = document.querySelectorAll('.js-carousel');
    if (carousel_elements !== null) {
        carousel_elements.forEach(carousel => {
            if (carousel !== null) {
                let crls = null;
                let prev_button = carousel.parentNode.querySelector('[data-element="button-prev"]');
                let next_button = carousel.parentNode.querySelector('[data-element="button-next"]');

                let slides_container = carousel.querySelector('.glide__slides');
                let slides = carousel.querySelectorAll('.glide__slide');
                let slides_count = 3;
                let width = document.body.clientWidth;
                if (width <= 991) slides_count = 2;
                if (width <= 600) slides_count = 1;
                if (slides.length > slides_count) {
                    crls = new Glide(carousel, {
                        perView: 2.5,
                        gap: '25',
                        bound: true,
                        breakpoints: {
                            991: {
                                perView: 2,
                            },
                            600: {
                                perView: 1,
                                autoplay: 3000
                            }
                        }
                    }).mount();

                    prev_button.addEventListener('click', (e) => {
                        e.preventDefault();

                        crls.go('<');
                    });
                    next_button.addEventListener('click', (e) => {
                        e.preventDefault();

                        crls.go('>');
                    });
                } else {
                    prev_button.style.display = 'none';
                    next_button.style.display = 'none';
                    slides_container.classList.add('static');
                }
            }
        });
    }

    let shps_crls = null;
    let shops_carousel = document.querySelector('.js-shops-carousel');
    if (shops_carousel !== null) {
        let prev_button = shops_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = shops_carousel.parentNode.querySelector('[data-element="button-next"]');

        shps_crls = new Glide(shops_carousel, {
            perView: 3,
            gap: '25',
            bound: true,
            breakpoints: {
                991: {
                    perView: 2,
                },
                600: {
                    perView: 1,
                    autoplay: 3000
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            shps_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            shps_crls.go('>');
        });
    }
    let choices_fields = document.querySelectorAll('.js-choices');
    if(choices_fields !== null){
        choices_fields.forEach(element => {
            new Choices(element, {
                itemSelectText: 'Kliknij aby wybrać'
            });
        })
    }
    let shops_list = document.querySelectorAll('.js-shops-list');
    if (shops_list !== null) {
        shops_list.forEach(item => {
            new ShopsList(item);
        });
    }

    let promotions_list = document.querySelectorAll('.js-promotions-list');
    if (promotions_list !== null) {
        promotions_list.forEach(item => {
            new ShopsList(item);
        });
    }

    let conveniences_list = document.querySelectorAll('.js-conveniences');
    if (conveniences_list !== null) {
        conveniences_list.forEach(item => {
            new Conveniences(item);
        });
    }

    let shop_tabs = document.querySelectorAll('.js-shop-tabs');
    if (shop_tabs !== null) {
        shop_tabs.forEach(item => {
            new ShopsTabs(item);
        });
    }

    let rect_sections = document.querySelectorAll('.js-section-calculate-rects');
    if (rect_sections !== null) {
        rect_sections.forEach(item => {
            new CalculateRects(item);
        });
    }

    let gallery_plan = document.querySelector('.js-gallery-plan');
    if (gallery_plan !== null) {
        new GalleryPlan(gallery_plan);
    }
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

