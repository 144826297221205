class Conveniences {
    constructor(selector) {
        this.selector = selector;
        this.container = this.selector.querySelector('[data-element="container"]');
        this.button = this.selector.querySelector('[data-element="button"]');
        this.hiddenElements = this.container.querySelectorAll('.conveniences__single--hidden');
        this.buttonClicked = false;

        if (this.button !== null) {
            this._buttonClick = this._buttonClick.bind(this);
            this.button.addEventListener('click', this._buttonClick);
        }
    }
    _buttonClick(e) {
        e.preventDefault();

        if (this.buttonClicked === false) {
            if (this.hiddenElements !== null) {
                this.hiddenElements.forEach((item) => {
                    console.log(item);
                    item.classList.remove('conveniences__single--hidden');
                });
                this.buttonClicked = true;
                this.button.style.display = 'none';
            }
        }
    }
}
export default Conveniences;