class CalculateRects {
    constructor(selector) {
        this.selector = selector;
        this.image = this.selector.querySelector('img');

        if (this.image !== null) {
            let image_height = this.image.clientHeight;
            let mod = 100;

            this.selector.style.setProperty('--section-rect-height', (image_height+mod) + 'px');
        }

        window.addEventListener('resize', () => {
            if (this.image !== null) {
                let image_height = this.image.clientHeight;
                let mod = 100;

                this.selector.style.setProperty('--section-rect-height', (image_height+mod) + 'px');
            }
        });
    }
}
export default CalculateRects;